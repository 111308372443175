@keyframes rotate {
  to { transform: rotate(360deg); }
}

.m-mapea-container *:focus {
  outline: none;
}

.m-areas>div.m-area>div.m-queryattributes.opened>button.m-panel-btn.opened-left {
    position: absolute;
    right: -2.53rem;
    left: unset !important;
    background-color: #4db6ac;
}

.m-control.m-container.m-queryattributes {
    background-color: rgb(238, 236, 236) !important;
    width: 100%;
}

.m-queryattributes button:focus, .m-queryattributes select:focus {
  outline: none !important;
}

/* .m-queryattributes */
#m-queryattributes-options-container {
    background-color: white !important;
    margin: auto !important;
    overflow: auto !important;
    padding: 10px;
    position: relative;
    width: 510px;
}

.m-queryattributes.collapsed {
    height: 40px !important;
    width: 40px !important;
}

.m-queryattributes.collapsed .m-panel-btn {
    color: white !important;
    width: 40px;
    height: 40px;
}

.m-queryattributes.collapsed .m-panel-btn::before {
    position: relative;
    bottom: 2px;
}

.m-area.m-bottom.m-right>.m-queryattributes.opened,
.m-area.m-bottom.m-left>.m-queryattributes.opened {
    width: 550px !important;
}

.m-queryattributes.opened #m-queryattributes-query-container>textarea {
    margin: auto;
    width: 520px;
}

.m-queryattributes.opened .m-panel-btn {
    background-color: transparent !important;
    color: white !important;
    position: fixed;
    z-index: 99999;
    box-shadow: none !important;
}

div.m-area.m-left .m-queryattributes.opened .m-panel-btn {
  left: 490px;
  top: 0px;
}

div.m-area.m-right .m-queryattributes.opened .m-panel-btn {
  right: 490px;
  top: 0px;
}

.m-queryattributes.opened #div-contenedor {
  position: fixed !important;
  top: 0px;
  height: 100vh;
  width: 520px;
  background-color: white !important;
  max-width: 520px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0 rgb(0 0 0 / 2%);
}

div.m-area.m-left .m-queryattributes.opened #div-contenedor {
  left: 0px;
}

div.m-area.m-right .m-queryattributes.opened #div-contenedor {
  right: 10px;
}

/* Titles */
.m-queryattributes div.title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    font-size: 15px;
    font-weight: bold;
    height: 39px;
    line-height: 40px;
    text-align: center;
    background-color: #71a7d3 !important;
    color: white !important;
}

/**
  Selection field container
*/

.m-queryattributes-field-container{
  text-align: center;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
  width: calc(100% - 20px);
}

.m-queryattributes-field-container select{
    font-family: Muli,"sans-serif"!important;
    display: inline-block;
    width: 75%;
    cursor: pointer;
    outline: 0;
    border: 0 solid #000;
    border-radius: 0;
    background: #e6e6e6;
    color: #000;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-left: 4%;
    white-space: nowrap;
    padding: 3px 11% 3px 10px;
}

.m-queryattributes-select-fields_arrow{
    position: absolute;
    top: 4px;
    right: 3.5em;
    pointer-events: none;
    border-color: #7b7b7b transparent transparent;
    border-style: solid;
    border-width: 8px 5px 0;
    background-color: #e6e6e6;
    margin: 1em;
}


#m-queryattributes-filter {
  text-align: center;
  margin-top: 0.2rem;
  margin-bottom: -0.4rem;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;/*center*/
  align-items: center;
}

#m-queryattributes-filter .m-queryattributes-filters-container {
  text-align: right;
}

.m-queryattributes-search-container{
  width: 50%;
}

/*#m-queryattributes-filter > div {
   width: 50%;
}*/

#m-queryattributes-filter > div input {
  width: 180px;
}

#m-queryattributes-filter .m-queryattributes-filters-container span {
  color: #6c6c6c;
  font-weight: bold;
  margin-right: 10px;
}


#m-queryattributes-filter button.filters i {
  font-size: 22px;
}

#m-queryattributes-filter #m-queryattributes-search-btn {
  font-size: 16px;
  position: relative;
  left: -5px;
  top: 1px;
  cursor: pointer;
}

/*e2m: spinner de filtro de resultados */
#m-queryattributes-searching-results {
  position: absolute;
  left: 190px;
  top: 48px;
  font-size: 1rem;
  animation: rotate 1s linear infinite;
  display:none;
}


#m-queryattributes-search-results {
  font-size: 0.9rem;
  position: relative;
  left: -15px;
  top: 1px;
  color:black;
}


/* Buttons */

/* e2m: Botones superiores de filtros */
#m-queryattributes-filter button.filters {
  padding: 5px 7px;
  background-color: #71a7d3;
  font-family: Muli,"sans-serif"!important;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #71a7d3;
  color: white;
  margin-right: 10px;
}

#m-queryattributes-filter button.filters:hover {
  color: black;
}

#m-queryattributes-filter button.filters:focus {
  color: black;
}

/* e2m: Botones inferiores de acciones */
#m-queryattributes-options-buttons {
    padding: 5px;
    display: flex;
    background-color: white !important;
    position: fixed;
    bottom: 0px;
    left: 10px;
}

#m-queryattributes-options-buttons>button {
    background-color: #71a7d3;
    border: 1px solid #71a7d3;
    border-radius: 4px;
    clear: both;
    color: white;
    margin: 3px;
    padding: 5px 7px;
    margin-right: 10px;
    cursor: pointer;
}

#m-queryattributes-options-buttons>button:hover{
  color: black;
}

/**
  * e2m: Definición de la tabla de atributos
  */

.m-queryattributes #m-queryattributes-table > p {
  text-align: center;
  width: 100%;
}

.m-queryattributes #m-queryattributes-table-container {
  max-height: 75vh;
  overflow: auto;
  margin: 0.5rem;
  margin-top: 1rem;
  border-top: 1px solid #71a7d3;
  border-bottom: 1px solid #71a7d3;
}

.m-queryattributes #m-queryattributes-table-container table {
  border-collapse: collapse;
  position: relative;
  margin: 0 auto;
}

.m-queryattributes #m-queryattributes-table-container table tbody tr {
  cursor: pointer;
}

/*.m-queryattributes #m-queryattributes-table-container table tr:hover {
  background-color: #71a7d3 !important;
  color: white !important;
}*/

.m-queryattributes #m-queryattributes-table-container table tr > td, .m-queryattributes #m-queryattributes-table-container table tr > th {
  border: 1px solid #71a7d3;
  padding: 8px;
}

.m-queryattributes #m-queryattributes-table-container table > tbody >tr:nth-child(odd) {
  background-color: #D1E6F8;
}

.m-queryattributes #m-queryattributes-table-container table > thead > tr > th {
  text-align: center;
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  top: -1px;
  font-weight: bold;
  background-color: #9DC6E8;
  color: white;
  min-width: 130px !important;
}

.m-queryattributes #m-queryattributes-table-container table > thead > tr > th > span {
  cursor: pointer;
}

.highlight-attrow{
  font-weight: bolder;
  color: #0c4b82; 
  background-color: cyan !important;
}


.m-dialog div#downloadFormat>button {
    background-color: #71a7d3 !important;
    border: 1px solid #71a7d3;
    border-radius: 4px;
    color: white !important;
    margin: 3px;
    padding: 5px 8px;
    cursor: pointer;
}

.m-dialog div#downloadFormat>select,
.m-dialog div#downloadFormat>button {
    display: block;
    margin: 10px auto;
}

.m-dialog div#downloadFormat>select {
    background-color: white;
    padding: 5px;
}

.m-queryattributes .m-queryattributes-table-loading {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  display: grid;
  overflow: hidden;
}

.m-queryattributes .m-queryattributes-table-loading p {
  margin-bottom: 0.5rem;
}

.m-queryattributes .m-queryattributes-table-loading span {
  font-size: 3rem;
  animation: rotate 1s linear infinite;
}


.m-queryattributes #m-queryattributes-options-information-container {
  padding: 0rem 1rem 1rem 1rem;
  width: 498px;
}

.m-queryattributes #m-queryattributes-options-information-container #m-queryattributes-information-content > p {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin: 0rem;
  background-color: #9DC6E8;
  padding: 0.5rem 0rem 0.5rem 0rem;
  color: white;
  border: 1px solid #71a7d3;
}

.m-queryattributes #m-queryattributes-options-information-container #m-queryattributes-information-content > div {
  border: 1px solid #6c6c6c;
  padding: 0rem 1rem;
  max-height: 300px;
  overflow-y: auto;
  border-top: none !important;
}

.m-queryattributes #m-queryattributes-options-information-container #m-queryattributes-information-content > p > span {
  position: absolute;
  right: 20px;
}

.m-queryattributes #m-queryattributes-options-information-container #m-queryattributes-information-content > p > span > span {
  cursor: pointer;
}

.m-queryattributes .m-queryattributes-table-noresult {
  text-align: center;
  margin-top: 2rem;
}

.m-queryattributes #m-queryattributes-information-content {
  word-break: break-word;
}

#m-queryattributes-information-content a.m-queryattributes-information-image-link {
  justify-content: center;
  display: flex;
}

#m-queryattributes-information-content a.m-queryattributes-information-image-link img {
  width: 400px;
  margin-top: 1rem;
}


/**
Information Window
*/

#m-queryattributes-information-content .lbl-descript{
  color:#71a7d3;
  font-size: 0.80rem;
  font-weight: bolder;
}

.m-queryattributes-information-button-link{
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: solid 1px #20538D;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), 0 1px 1px rgba(0, 0, 0, 0.2);
  background: #71a7d3;
  color: #FFF;
  padding: 8px 12px;
  text-decoration: none;
  margin-top: 8px;
}

/**
Attribute Table
*/

.m-queryattributes-table-image-link img{
  max-width: 150px;
}