@font-face {
  font-family: 'queryattributes';
  src: url('../fonts/queryattributes.eot?67130516');
  src: url('../fonts/queryattributes.eot?67130516#iefix') format('embedded-opentype'),
       url('../fonts/queryattributes.woff2?67130516') format('woff2'),
       url('../fonts/queryattributes.woff?67130516') format('woff'),
       url('../fonts/queryattributes.ttf?67130516') format('truetype'),
       url('../fonts/queryattributes.svg?67130516#queryattributes') format('svg');
  font-weight: normal;
  font-style: normal;
}

div.ol-overlaycontainer-stopevent > div > div.m-area > div.m-panel.m-queryattributes > button:before,
.m-queryattributes [class^="icon-"]:before, .m-queryattributes [class*=" icon-"]:before {
  font-family: "queryattributes" !important;
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.m-queryattributes .icon-flecha-derecha:before { content: '\e919'; }
.m-queryattributes .icon-flecha-izquierda:before { content: '\e91d'; }
.m-queryattributes .icon-tabla:before { content: '\e930'; }
.m-queryattributes .icon-spinner:before { content: '\e953'; }
.m-queryattributes .icon-zoom:before { content: '\e964'; }
.m-queryattributes .icon-sort:before { content: '\f0dc'; }
.m-queryattributes .icon-cerrar:before { content: '\e905'; }
.m-queryattributes .icon-desplegar:before { content: '\e916'; }
.m-queryattributes .icon-colapsar:before { content: '\e918'; }

.m-queryattributes .g-cartografia-flecha-izquierda:before {
  content: '\e91d';
}

.m-queryattributes .g-cartografia-flecha-derecha:before {
  content: '\e919';
}

